import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import {
  Container,
  Image,
  Dropdown,
  Nav,
  Button,
  Col,
  Row,
  Alert,
} from 'react-bootstrap'
import { LogoutOptions } from '@auth0/auth0-spa-js'
import {
  useAuth,
  IAuthUser,
  useImpersonatingUserOrUser,
  isAdminOrBBEUserOrSuperAdmin,
  isSuperAdmin,
  isChannelPartnerUser,
  useAuthInfo,
  useEditLevel,
} from '../state/modules/auth'
import { IStyledComponentPropTypes, Variant } from '../common/types'
import logo192 from '../assets/logo192.png'
import {
  useDeleteImpersonation,
  isSimpleBBEUser,
  isSimpleChannelPartner,
  isSimpleEntity,
  useImpersonatingUser,
} from '../state/modules/impersonations'
import { isDefined } from '../utils/typeGuards'
import { isProductionHost } from '../utils/enviroment'
import { stringToBoolean } from '../utils/string'
import { TextButton } from '../components/common/TextButton'

const avatar =
  'https://ctorthopaedic.com/wp-content/uploads/2020/10/istockphoto-1129748188-612x612.jpg'

type IUserInfoCardPropTypes = {
  user?: IAuthUser
  logout: (options?: LogoutOptions) => void
}

function UserInfoCard({ user, logout }: IUserInfoCardPropTypes) {
  const returnTo = isProductionHost()
    ? process.env.REACT_APP_AUTH0_RETURNTO
    : process.env.REACT_APP_AUTH0_STAGING_RETURNTO

  return (
    <Dropdown.Menu>
      <Container className="justify-content-center text-center">
        <Image
          src={user?.picture || avatar}
          alt={'profile photo'}
          roundedCircle
          width="83px"
          className="ml-5 mr-5 mt-3 mb-3"
        />
        {(user?.given_name || user?.family_name) && (
          <p>
            <strong>
              {user?.given_name} {user?.family_name}
            </strong>
          </p>
        )}
        <p>
          <small>{user?.name}</small>
        </p>
        <hr></hr>
        <Button onClick={() => logout({ returnTo })} className="mb-2">
          <small>logout</small>
        </Button>
      </Container>
    </Dropdown.Menu>
  )
}

type IPicDropDownTogglePropTypes = {
  user?: IAuthUser
}

function PicDropDownToggle({ user }: IPicDropDownTogglePropTypes) {
  return (
    <Dropdown.Toggle variant="link" className="py-0">
      <Image
        src={user?.picture || avatar}
        alt={'profile photo'}
        roundedCircle
        width="32px"
      />
    </Dropdown.Toggle>
  )
}

function Header({ className }: IStyledComponentPropTypes) {
  const { logout, user, isLoading, isAuthenticated } = useAuth()
  const impersonatingUser = useImpersonatingUser()
  const impersonatingUserOrUser = useImpersonatingUserOrUser()
  const deleteImpersonation = useDeleteImpersonation()

  const {
    settings: { enableProjectsPage, enableDashboard },
  } = useAuthInfo()

  const [editLevel, setEditLevel] = useEditLevel()

  return !isLoading && isAuthenticated ? (
    <Container as="nav" fluid className={className}>
      <Row>
        <Col xl={{ span: 10, offset: 1 }} style={{ position: 'static' }}>
          <Nav activeKey="/home" className="links">
            <NavLink to="/" tabIndex={-1} className="logo">
              <Image src={logo192} alt="Black Bear Energy" height={32} />
            </NavLink>
            {enableDashboard && (
              <NavLink
                exact={true}
                defaultChecked
                activeClassName="active"
                className="btn btn-link first-link"
                to="/"
              >
                Dashboard
              </NavLink>
            )}
            <NavLink
              exact={true}
              activeClassName="active"
              className="btn btn-link"
              to="/rfps"
            >
              RFPs
            </NavLink>
            {enableProjectsPage && (
              <NavLink
                exact={true}
                activeClassName="active"
                className="btn btn-link"
                to="/manage/projects"
              >
                Projects
              </NavLink>
            )}
            <NavLink
              exact={true}
              activeClassName="active"
              className="btn btn-link"
              to="/manage/users"
            >
              Manage{' '}
              {impersonatingUserOrUser &&
              isAdminOrBBEUserOrSuperAdmin(impersonatingUserOrUser)
                ? 'Users'
                : 'Profile'}
            </NavLink>
            <NavLink
              exact={true}
              activeClassName="active"
              className="btn btn-link"
              to="/manage/documents"
            >
              Documents
            </NavLink>
            {stringToBoolean(process.env.REACT_APP_FEATURE_FLAG_BID_HISTORY) &&
              impersonatingUserOrUser &&
              isChannelPartnerUser(impersonatingUserOrUser) && (
                <NavLink
                  exact={true}
                  activeClassName="active"
                  className="btn btn-link"
                  to="/bid/history"
                >
                  Bid History
                </NavLink>
              )}
            {impersonatingUserOrUser &&
              isAdminOrBBEUserOrSuperAdmin(impersonatingUserOrUser) && (
                <NavLink
                  exact={true}
                  activeClassName="active"
                  className="btn btn-link"
                  to="/sites/upload"
                >
                  Upload Sites
                </NavLink>
              )}
            {impersonatingUserOrUser &&
              isSuperAdmin(impersonatingUserOrUser) && (
                <NavLink
                  exact={true}
                  activeClassName="active"
                  className="btn btn-link"
                  to="/user-import"
                >
                  User Import
                </NavLink>
              )}
            <span className="spacer" />
            <div className="user-card">
              <Dropdown>
                <PicDropDownToggle user={user} />
                <UserInfoCard user={user} logout={logout} />
              </Dropdown>
            </div>
          </Nav>
        </Col>
      </Row>
      {isDefined(impersonatingUser) &&
      isDefined(impersonatingUser.impersonatingDetails) ? (
        <Row className="mt-2">
          <Col xl={{ span: 10, offset: 1 }}>
            <Alert variant={Variant.WARNING} className="mb-0">
              You're currently impersonating "
              {isSimpleBBEUser(impersonatingUser.impersonatingDetails)
                ? 'Black Bear Energy User'
                : isSimpleChannelPartner(impersonatingUser.impersonatingDetails)
                ? impersonatingUser.impersonatingDetails.channelPartnerName
                : isSimpleEntity(impersonatingUser.impersonatingDetails)
                ? impersonatingUser.impersonatingDetails.entityName
                : impersonatingUser.impersonatingDetails.email}
              ".
              <span style={{ display: 'inline-block', margin: '0 10px' }}>
                Edit level:&nbsp;
                {editLevel === 'impersonating' && (
                  <span title="Edit with privileges of impersonated user">
                    Impersonating
                  </span>
                )}
                {editLevel === 'own' && (
                  <span title="Edit with full privileges you have (when supported)">
                    Admin
                  </span>
                )}
                &nbsp;
                <TextButton
                  onClick={() =>
                    setEditLevel(
                      editLevel === 'impersonating' ? 'own' : 'impersonating'
                    )
                  }
                  style={{ fontSize: 'small' }}
                >
                  (Switch)
                </TextButton>
              </span>
              <Button
                variant="link"
                className="inline-block font-italic"
                onClick={deleteImpersonation}
              >
                Stop impersonation
              </Button>
            </Alert>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </Container>
  ) : (
    <></>
  )
}

export default styled(Header)`
  position: sticky;
  left: 0;
  z-index: 1000;

  .nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .first-link {
    margin-left: 0.75rem;
  }
  .links a.btn {
    color: var(--black) !important;
  }
  .links a.btn.active {
    color: var(--primary) !important;
    background: var(--bg-primary-gray) !important;
  }
  .links a.btn:active,
  .links a.btn:hover,
  .links a.btn:focus {
    background: var(--bg-primary-gray) !important;
    color: var(--primary) !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  .links a.btn.logo.active {
    background: transparent;
  }
  .spacer {
    flex: 1;
  }
  .dropdown-toggle {
    box-shadow: none !important;
  }
  .dropdown-toggle:active {
    box-shadow: none !important;
  }
  .dropdown-toggle::after {
    content: none;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    .links a.logo {
      position: absolute;
      left: 1rem;
    }
    .user-card {
      position: absolute;
      right: 1rem;
    }
    .first-link {
      margin-left: -0.75rem;
    }
  }
`
