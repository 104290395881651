import {
  initialState as notificationsInitialState,
  sliceName as notificationsSliceName,
} from './modules/notifications'
import {
  initialState as confirmationInitialState,
  sliceName as confirmationSliceName,
} from './modules/confirmation'
import {
  initialState as authInitialState,
  sliceName as authSliceName,
} from './modules/auth'
import {
  initialState as usersInitialState,
  sliceName as usersSliceName,
} from './modules/users'
import {
  initialState as impersonationsInitialState,
  sliceName as impersonationsSliceName,
} from './modules/impersonations'
import {
  initialState as channelPartnersInitialState,
  sliceName as channelPartnersSliceName,
} from './modules/channelPartners'
import {
  initialState as entitiesInitialState,
  sliceName as entitiesSliceName,
} from './modules/entities'
import {
  initialState as entityPortalAttachmentsInitialState,
  sliceName as entityPortalAttachmentsSliceName,
} from './modules/entityPortalAttachments'
import {
  initialState as customerRfpsInitialState,
  sliceName as customerRfpsSliceName,
} from './modules/customerRfps'
import {
  initialState as bidHistoryInitialState,
  sliceName as bidHistorySliceName,
} from './modules/bidHistory'
import {
  initialState as userInviteTextInitialState,
  sliceName as userInviteTextSliceName,
} from './modules/userInviteText'
import {
  initialState as termsOfServiceInitialState,
  sliceName as termsOfServiceSliceName,
} from './modules/termsOfService'
import {
  initialState as portalUsersInitialState,
  sliceName as portalUsersSliceName,
} from './modules/portalUsers'
import {
  initialState as siteRfpsInitialState,
  sliceName as siteRfpsSliceName,
} from './modules/siteRfps'
import {
  initialState as siteRfpFieldsInitialState,
  fieldsSliceName as siteRfpFieldsSliceName,
} from './modules/siteRfps'
import {
  initialState as userReportsInitialState,
  sliceName as userReportsSliceName,
} from './modules/userReports'

const initialState = {
  [confirmationSliceName]: confirmationInitialState,
  [notificationsSliceName]: notificationsInitialState,
  [authSliceName]: authInitialState,
  [usersSliceName]: usersInitialState,
  [impersonationsSliceName]: impersonationsInitialState,
  [channelPartnersSliceName]: channelPartnersInitialState,
  [entitiesSliceName]: entitiesInitialState,
  [entityPortalAttachmentsSliceName]: entityPortalAttachmentsInitialState,
  [customerRfpsSliceName]: customerRfpsInitialState,
  [bidHistorySliceName]: bidHistoryInitialState,
  [userInviteTextSliceName]: userInviteTextInitialState,
  [termsOfServiceSliceName]: termsOfServiceInitialState,
  [portalUsersSliceName]: portalUsersInitialState,
  [siteRfpsSliceName]: siteRfpsInitialState,
  [siteRfpFieldsSliceName]: siteRfpFieldsInitialState,
  [userReportsSliceName]: userReportsInitialState,
}

export type IState = typeof initialState

export default initialState
