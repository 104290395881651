import {
  IDeveloperField,
  IColumnHookInput,
  IColumnHookOutput,
} from 'dromo-uploader-react'
import { IReviewStepData, IRowHookInput } from 'dromo-uploader-js'
import {
  IPublicConnectionMethods,
  IFieldMetadata,
  IRowToAdd,
  IRowHookOutput,
} from 'dromo-uploader-js/dist/interfaces'
import {
  fieldSchema,
  siteActionSchema,
  zipCodeRegex,
  validations,
} from './FieldSchema'
import {
  SiteAction,
  SiteListType,
} from '@black-bear-energy/black-bear-energy-common'
import { badGeocodeMsg, failedGeocodeMsg } from './Geocoder'
import { upsertCounts } from '../../containers/SiteUpload'
import { AxiosInstance } from 'axios'
import { dupChildMsg, dupParentMsg } from './Deduplication'

/** Keys of fields that are not saved to the database and only exist within the upload session */
export const browserOnlyFields = [
  'existingRecordId',
  'rowId',
  'maybeSold',
  'isGeocodeValid',
]

export interface SiteUploadRecord {
  [key: string]: unknown
}

export interface DuplicateCheckResult {
  dupCount: number
  rowsToAdd: IRowToAdd[]
  rowIdsToRemove: string[]
}

// Postgres queries are limited to 65535 parameters. Stay
// well under this for performance reasons
const maxUploadParameterCount = 30000

const nameSchema = fieldSchema.find(
  (fs) => fs.key === 'name'
) as IDeveloperField
const citySchema = fieldSchema.find(
  (fs) => fs.key === 'city'
) as IDeveloperField
const stateSchema = fieldSchema.find(
  (fs) => fs.key === 'state'
) as IDeveloperField
const zipCodeSchema = fieldSchema.find(
  (fs) => fs.key === 'zipCode'
) as IDeveloperField
const latSchema = fieldSchema.find(
  (fs) => fs.key === 'latitude'
) as IDeveloperField
const longSchema = fieldSchema.find(
  (fs) => fs.key === 'longitude'
) as IDeveloperField
const notesSchema = fieldSchema.find(
  (fs) => fs.key === 'notes'
) as IDeveloperField

export function addColumnsIfNeeded(
  instance: IPublicConnectionMethods,
  previewData: IReviewStepData
) {
  const headers = Object.values(previewData.headerMapping)
  const firstField = getFirstHeaderKey(previewData.fields)
  instance.addField(siteActionSchema, {
    before: firstField,
  })

  if (!headers.includes(nameSchema.key)) {
    instance.addField(nameSchema, { after: siteActionSchema.key })
  }
  if (!headers.includes(citySchema.key)) {
    instance.addField(citySchema, { after: 'address' }) // address is required
  }
  if (!headers.includes(stateSchema.key)) {
    instance.addField(stateSchema, { after: citySchema.key })
  }
  if (!headers.includes(zipCodeSchema.key)) {
    instance.addField(zipCodeSchema, { after: stateSchema.key })
  }
  // We only need to check one because of the validation logic that requires both or none
  if (!headers.includes(latSchema.key)) {
    instance.addField(latSchema, { after: zipCodeSchema.key })
    instance.addField(longSchema, { after: latSchema.key })
  }
  if (!headers.includes(notesSchema.key)) {
    instance.addField(notesSchema, { after: longSchema.key })
  }
}

/** Find the key of the first column that will be shown in the Review tab */
function getFirstHeaderKey(fields: IFieldMetadata): string {
  const header = Object.keys(fields).reduce(
    (currMinKey: string | null, currKey) => {
      const currHeaderInd = fields[currKey].fileHeaderIndex

      const currMinHeaderInd = currMinKey
        ? fields[currMinKey].fileHeaderIndex
        : null

      if (
        currHeaderInd !== null &&
        (currMinHeaderInd === null || currHeaderInd < currMinHeaderInd)
      ) {
        return currKey
      }

      return currMinKey
    },

    null
  )

  if (!header) {
    throw new Error('Could not find first column header')
  }

  return header
}

export function addZipCodeLeadingZeros(
  values: IColumnHookInput[]
): IColumnHookOutput[] {
  return values.map((row) => {
    const value = row.value as string | undefined

    // empty or contains invalid characters - can't process
    if (!value || /[^\d-]/.test(value)) {
      return row
    }
    // zip code is valid - don't change
    if (zipCodeRegex.test(value)) {
      return row
    }
    // add leading zeros
    const zips = value.split('-')
    const hasAddOn = zips.length === 2
    const paddedZip = `${zips[0].padStart(5, '0')}${
      hasAddOn ? '-' + zips[1].padStart(4, '0') : ''
    }`
    const newRow = {
      index: row.index,
      value: paddedZip,
      info: [
        {
          message: 'Prepended leading zero(s) to zip code',
          level: 'info' as const,
        },
      ],
    }
    return newRow
  })
}

export function parseDateValues(
  values: IColumnHookInput[],
  fullDate: boolean
): IColumnHookOutput[] {
  return values.map((row) => {
    // empty
    if (!row.value) {
      return row
    }
    const timeStamp = Date.parse(String(row.value))
    // cannot be parsed as a date
    if (isNaN(timeStamp)) {
      return {
        index: row.index,
        value: null,
        info: [
          {
            message: `Could not parse "${row.value}" as a date`,
            level: 'info',
          },
        ],
      }
    }

    const date = new Date(timeStamp)
    const newValue = fullDate
      ? date.toLocaleDateString('en-US', { timeZone: 'UTC' })
      : date.getUTCFullYear()

    return {
      index: row.index,
      value: newValue,
    }
  })
}

export function updateCellMessages(record: IRowHookInput): IRowHookOutput {
  const recordClone = { ...record }
  const isIgnore = recordClone.row.siteAction.value === SiteAction.Ignore

  for (const key of Object.keys(recordClone.row)) {
    const originalMsgs = recordClone.row[key].info ?? []

    if (isIgnore) {
      // if the user wants to ignore this record, remove all error messages
      const filteredMsgs = originalMsgs.filter((msg) => msg.level !== 'error')
      recordClone.row[key].info = filteredMsgs
      continue
    }
    // keep any info/warning messages and any error messages from other validation logic
    let filteredMsgs = originalMsgs.filter(
      (msg) =>
        msg.message === badGeocodeMsg ||
        msg.message === failedGeocodeMsg ||
        msg.level !== 'error'
    )
    if (key === 'siteAction') {
      // for the site action cell, remove the dup warnings when the user updates the row
      filteredMsgs = filteredMsgs.filter(
        (msg) => ![dupChildMsg, dupParentMsg].includes(msg.message)
      )
    }
    const validator = validations[key]
    if (!validator) {
      recordClone.row[key].info = filteredMsgs
      continue
    }
    const value = recordClone.row[key].value as unknown
    // validate the value and add messages if needed
    recordClone.row[key].info = validator(value ? String(value) : '').concat(
      filteredMsgs
    )
  }

  return recordClone
}

export function convertZeroToNull(
  values: IColumnHookInput[]
): IColumnHookOutput[] {
  return values.map((row) => {
    // Return the row unchanged if its value is not zero.
    if (row.value !== 0) {
      return row
    }

    const rowOutput: IColumnHookOutput = {
      index: row.index,
      value: null,
      info: [
        {
          message: 'Converted 0 to null',
          level: 'info',
        },
      ],
    }
    return rowOutput
  })
}

export function getUploadSummary(siteActions: SiteAction[]): string {
  const counts = siteActions.reduce(
    (acc, currSiteActions) => {
      switch (currSiteActions) {
        case SiteAction.Ignore:
        case SiteAction.Review:
          return { ...acc, ignore: acc.ignore + 1 }
        case SiteAction.New:
          return { ...acc, new: acc.new + 1 }
        case SiteAction.Sold:
          return { ...acc, sold: acc.sold + 1 }
        case SiteAction.Update:
          return { ...acc, update: acc.update + 1 }
      }
    },
    {
      new: 0,
      ignore: 0,
      sold: 0,
      update: 0,
    }
  )
  const summaries: string[] = []
  if (counts.new > 0) {
    summaries.push(
      `${counts.new} new site${counts.new > 1 ? 's' : ''} will be added`
    )
  }
  if (counts.ignore > 0) {
    summaries.push(
      `${counts.ignore} site${counts.ignore > 1 ? 's' : ''} will be ignored`
    )
  }
  if (counts.sold > 0) {
    summaries.push(
      `${counts.sold} existing site${
        counts.sold > 1 ? 's' : ''
      } will be marked as sold`
    )
  }
  if (counts.update > 0) {
    summaries.push(
      `${counts.update} existing site${
        counts.update > 1 ? 's' : ''
      } will be updated`
    )
  }
  return `${summaries.join('\n')}\n\n${
    siteActions.length
  } total (valid) sites in upload list`
}

export async function chunkSiteListUpload(
  sites: SiteUploadRecord[],
  siteListType: SiteListType,
  api: AxiosInstance
): Promise<upsertCounts> {
  const batches = batchSites(sites, maxUploadParameterCount)
  const batchCount = batches.length
  const siteBatchCounts: upsertCounts[] = []
  for (let i = 0; i < batchCount; i++) {
    const siteBatchCount = await uploadSiteBatch(batches[i], siteListType, api)
    siteBatchCounts.push(siteBatchCount)
  }
  const finalCounts: upsertCounts = siteBatchCounts.reduce(
    (acc, currCount) => ({
      newCount: acc.newCount + currCount.newCount,
      updateCount: acc.updateCount + currCount.updateCount,
    }),
    { newCount: 0, updateCount: 0 }
  )
  return finalCounts
}

function batchSites(
  sites: SiteUploadRecord[],
  maxBatchParameterCount: number
): SiteUploadRecord[][] {
  const fieldCount = Object.keys(sites[0]).length - 1 // subtract 1 for the site action, which isn't stored in the db
  // parameter count = sites count * field count
  // To get the batch size in # of sites, divide the (max param count per batch) by the field count
  const maxBatchSiteCount = Math.floor(maxBatchParameterCount / fieldCount)
  const batchCount = Math.ceil(sites.length / maxBatchSiteCount)
  const batches = []
  for (let i = 0; i < batchCount; i++) {
    const startIndex = i * maxBatchSiteCount
    const batch = sites.slice(startIndex, startIndex + maxBatchSiteCount)
    batches.push(batch)
  }
  return batches
}

async function uploadSiteBatch(
  sites: SiteUploadRecord[],
  siteListType: SiteListType,
  api: AxiosInstance
): Promise<upsertCounts> {
  const response = await api.post<{
    newRecordCount: number
    updatedRecordCount: number
  }>('/sites', {
    sites,
    siteListType,
  })

  return {
    newCount: response.data.newRecordCount,
    updateCount: response.data.updatedRecordCount,
  }
}
