import React from 'react'
import ReactDOM from 'react-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { StateProvider } from './state/store'
import { AuthContextProvider } from './state/modules/auth'
import App from './App'
import { isProductionHost } from './utils/enviroment'

ReactDOM.render(
  <React.StrictMode>
    <StateProvider>
      <DndProvider backend={HTML5Backend}>
        <AuthContextProvider
          domain={
            (isProductionHost()
              ? process.env.REACT_APP_AUTH0_DOMAIN
              : process.env.REACT_APP_AUTH0_STAGING_DOMAIN)!
          }
          clientId={
            (isProductionHost()
              ? process.env.REACT_APP_AUTH0_CLIENT_ID
              : process.env.REACT_APP_AUTH0_STAGING_CLIENT_ID)!
          }
          redirectUri={window.location.origin}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE}
          scope={process.env.REACT_APP_AUTH0_SCOPE}
        >
          <App />
        </AuthContextProvider>
      </DndProvider>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals().catch(() => {
  throw new Error('reportWebVitals failed')
})
